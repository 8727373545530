<template>
   <div class="d-flex align-center centrum-logo" @click="navigateToHome">
      <img src="../assets/images/logos/logo2.png" height="80"/>
            <div class="d-flex flex-column align-center ml-4 text-left"><span class="lexend logo-text" >CENTRUM ACADEMY</span>
              <span class="lexend logo-quotes" >Consultancy Pvt. Ltd</span>
            </div>
          </div>
</template>

<script>
export default {
methods: {
  navigateToHome() {
    this.$router.push('/')
  }
}
}
</script>

<style scoped>
.logo-quotes {
  font-size: 18px;
    color: blue !important;
    font-weight: 900;
    /* transform: scaleY(1.3); */
    letter-spacing: 1px;
    padding-right: 2px;
    margin-top: -8px;
}

.logo-text {
  font-weight: 800;
  font-size:23px ;
  transform: scaleY(1.3);
  color: red !important;
  letter-spacing: 1px;
  white-space:nowrap;

}
.centrum-logo {
  cursor: pointer;
}
</style>

<template>
  <v-app>
    <v-main>
      <Navigation />
      <router-view />
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Navigation,
    Footer,
  },

  data: () => ({}),
};
</script>
<style></style>
